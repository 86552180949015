












































































import $ from "jquery";
import { Component, Vue, Watch } from 'vue-property-decorator'
import {  listCallOaResultVoByCategoryId } from "@/api";
interface IProduct {
  // id: number
  //爬虫
  bjxh: string
  sccj: string
  partDesc: string
  kc: number
  jg: Array<string>
  showMore: boolean,
  //OA
  id:string,
  materialId:number,
  partnumber:string,
  rsp: Array<string>,
  description:string,
  mft:string,
  leadtime:string,
  qty:string,
  pc:string
}
@Component({
  components: {},
})
//分类搜索结果
export default class SearchResult extends Vue {
  keyword = ''
  productsSummary: Array<IProduct> = []
  hxwStatus = window.localStorage.getItem("hxwStatus")
  summaryLoading = true;

  
  @Watch('$route.params.keyword')
    onIdChanged(): void {
      this.keyword = this.$route.params.keyword.trim();
      this.summaryLoading = true;
      
      this.listCallOaResultVoByCategoryId();
    
    }

  mounted(): void {
   this.keyword = this.$route.params.keyword.trim();
    // this.getProducts()
    this.listCallOaResultVoByCategoryId();
    
  }
  listCallOaResultVoByCategoryId(): void{
  
    listCallOaResultVoByCategoryId(this.keyword).then(res => {
      // console.log(res)
      this.summaryLoading = false;
      if(res.status == 200){
          if(res.data.code == 200){
            
           console.log(res.data.data)
           this.productsSummary = res.data.data;
        
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
  handleBuy(item: IProduct): void {
      console.log(item)
      this.$message.success("询价咯")
    }
    showMore(item: IProduct): void {
      console.log(item)
      item.showMore = true
    }
}
